export class APIService {

    static verify = async (data) => {
        return await fetch('/api/edu-schedule-bot/auth/verify', {
            headers: {
                'content-type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
    }

    static mailingStart = async (token, data) => {
        return await fetch('/api/edu-schedule-bot/mailing', {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            body: JSON.stringify(data)
        })
    }

    static mailingGet = async (token) => {
        return await fetch('/api/edu-schedule-bot/mailing', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static mailingEdit = async (token, data) => {
        return await fetch('/api/edu-schedule-bot/mailing', {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'PUT',
            body: JSON.stringify(data)
        })
    }

    static me = async (token) => {
        return await fetch('/api/edu-schedule-bot/users/me', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getGroups = async (token) => {
        return await fetch('/api/edu-schedule-bot/groups', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getRoles = async (token) => {
        return await fetch('/api/edu-schedule-bot/roles', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getSelectedGroups = async (token, chatId) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/groups`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getSettings = async (token) => {
        return await fetch('/api/edu-schedule-bot/settings', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static searchUsers = async (token, params) => {
        return await fetch('/api/edu-schedule-bot/users?' + new URLSearchParams({
            ...params
        }).toString(), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static searchContacts = async (token, params) => {
        return await fetch('/api/edu-schedule-bot/staff?' + new URLSearchParams({
            ...params
        }).toString(), {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getStaffCardById = async (token, userId) => {
        return await fetch('/api/edu-schedule-bot/staff/' + userId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static getUser = async (token, userId) => {
        return await fetch('/api/edu-schedule-bot/users/' + userId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
    }

    static editUser = async (token, userId, data) => {
        return await fetch('/api/edu-schedule-bot/users/' + userId, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'PATCH',
            body: JSON.stringify(data)
        })
    }

    static editSettings = async (token, settings) => {
        return await fetch('/api/edu-schedule-bot/settings', {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PUT",
            body: JSON.stringify(settings)
        })
    }

    static editGroups = async (token, chatId, groups) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/groups`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PUT",
            body: JSON.stringify(groups)
        })
    }

    static editStaffCardById = async (token, chatId, data) => {
        return await fetch(`/api/edu-schedule-bot/staff/${chatId}`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PATCH",
            body: JSON.stringify(data)
        })
    }

    static editName = async (token, userId, data) => {
        return await fetch('/api/edu-schedule-bot/users/' + userId, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PATCH",
            body: JSON.stringify(data)
        })
    }

    static registerStudent = async (token, data) => {
        return await fetch('/api/edu-schedule-bot/auth/register/student', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'content-type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)
        })
    }

    static registerTeacher = async (token, data) => {
        return await fetch('/api/edu-schedule-bot/auth/register/staff', {
            headers: {
                'Authorization': 'Bearer ' + token,
                'content-type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify(data)
        })
    }

    static createChatMathRule = async (token, chatId, data) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/matchRules`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "POST",
            body: JSON.stringify(data)
        })
    }

    static fetchChatMathRules = async (token, chatId) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/matchRules`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: "GET"
        })
    }

    static fetchChatMathRuleById = async (token, chatId, ruleId) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/matchRules/${ruleId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: "GET"
        })
    }

    static editMatchRule = async (token, chatId, ruleId, data) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/matchRules/${ruleId}`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PATCH",
            body: JSON.stringify(data)
        })
    }

    static fetchDeleteChatMathRuleById = async (token, chatId, ruleId) => {
        return await fetch(`/api/edu-schedule-bot/chats/${chatId}/matchRules/${ruleId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: "DELETE",
        })
    }

    static getNotifySettingsById = async (token, notifyId) => {
        return await fetch(`/api/edu-schedule-bot/notify/${notifyId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: "GET"
        })
    }

    static editNotifySettingsById = async (token, notifyId, data) => {
        return await fetch(`/api/edu-schedule-bot/notify/${notifyId}`, {
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: "PATCH",
            body: JSON.stringify(data)
        })
    }
}